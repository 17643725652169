import React from 'react'
import { Theme } from '../../style/Theme'
import { css } from '../../style/css'

const Subheading = ({ text, sup = false }: { text: string; sup: boolean }) => {
    return (
        <div
            css={(th: Theme) => css`
                text-align: left;
                margin-bottom: 15px;
            `}
        >
            <h4>
                {text} {sup && <sup>1</sup>}
            </h4>
        </div>
    )
}

export default Subheading
