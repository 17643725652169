import React from 'react'
import { Theme } from '../../style/Theme'
import { css } from '../../style/css'

const Heading = ({ text }: { text: string }) => {
    return (
        <h3
            css={(th: Theme) => css`
                text-align: center;
                font-size: 24px;
            `}
        >
            {text}
        </h3>
    )
}

export default Heading
