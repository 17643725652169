import React, { FC } from 'react'

type TableProps = {
    tableContent: {
        title: string
        rows: {
            left: string
            right: string
        }[]
    }[]
}

const Table: FC<TableProps> = ({ tableContent }) => {
    return (
        <table>
            <tbody>
                {tableContent &&
                    tableContent.map((table, contentIndex) => (
                        <React.Fragment key={contentIndex}>
                            <tr>
                                <th colSpan={2}>{table.title}</th>
                            </tr>
                            {table &&
                                table.rows &&
                                table.rows.map((row, index) => (
                                    <tr key={index}>
                                        <td>{row.left}</td>
                                        <td>{row.right}</td>
                                    </tr>
                                ))}
                        </React.Fragment>
                    ))}
            </tbody>
        </table>
    )
}

export default Table
