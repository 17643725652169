import React from 'react'
import Layout from 'src/components/Layout'
import PageHeader from 'src/components/PageHeader'
import { t } from 'src/i18n/translation/t'
import { PageContext } from 'src/page/types'
import { css } from 'src/style/css'
import Container from '../../components/Container'
import Heading from '../../components/privacy-policy/Heading'
import Subheading from '../../components/privacy-policy/Subheading'
import Table from '../../components/privacy-policy/Table'

const PrivacyPolicyPage = ({ pageContext }: { pageContext: PageContext }) => {
    const { msgs } = pageContext
    return (
        <Layout pageCtx={pageContext}>
            <PageHeader>{t(msgs.privacyPolicy.title)}</PageHeader>
            <Container
                css={css`
                    ul {
                        line-height: 1.8;
                        font-size: 14px;
                        color: #555555;
                        margin-left: 1rem;
                        list-style-type: disc;
                    }
                `}
            >
                <Heading text={t(msgs.privacyPolicy.section1.heading)} />
                <Subheading
                    text={t(msgs.privacyPolicy.section1.subheading)}
                    sup={true}
                />
                <p>{t(msgs.privacyPolicy.section1.p1)}</p>
                <ul>
                    {msgs.privacyPolicy.section1.ul1.map((list, index) => (
                        <li key={index}>{list}</li>
                    ))}
                </ul>
                {msgs.privacyPolicy.section1.p2.map((paragraph, index) => (
                    <p key={index}>{paragraph}</p>
                ))}
                <p>{t(msgs.privacyPolicy.section1.p3)}</p>
                <ul>
                    {msgs.privacyPolicy.section1.ul2.map((list, index) => (
                        <li key={index}>{list}</li>
                    ))}
                </ul>
                {msgs.privacyPolicy.section1.p4.map((paragraph, index) => (
                    <p key={index}>{paragraph}</p>
                ))}
                <Heading text={t(msgs.privacyPolicy.section2.heading)} />
                <Subheading
                    text={t(msgs.privacyPolicy.section2.subheading)}
                    sup={false}
                />
                <Table
                    tableContent={msgs.privacyPolicy.section2.tableSections}
                />
                <Heading text={t(msgs.privacyPolicy.section3.heading)} />
                <Subheading
                    text={t(msgs.privacyPolicy.section3.subheading)}
                    sup={false}
                />
                <Table
                    tableContent={msgs.privacyPolicy.section3.tableSections}
                />
                <Heading text={t(msgs.privacyPolicy.section4.heading)} />
                <Subheading
                    text={t(msgs.privacyPolicy.section4.subheading)}
                    sup={false}
                />
                <Table
                    tableContent={msgs.privacyPolicy.section4.tableSections}
                />
                <sup>1</sup> {t(msgs.privacyPolicy.sup)}
            </Container>
        </Layout>
    )
}
export default PrivacyPolicyPage
